.rcp-saturation {
  border-radius: 0;
}

.rcp-body {
  padding: 0;
  padding-top: 8px;
}

.rcp-hue {
  border-radius: 0;
}

.rcp-fields-element-label {
  display: none;
}

.rcp-saturation-cursor {
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
}

.rcp-hue-cursor {
  width: 12px;
  height: 12px;
  transform: translateX(-50%);
}
